import React from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { Button } from 'react-bootstrap';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useForm } from "react-hook-form";
import '../styles/welcome.scss';
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { URLS } from "../_config";

export default function Login() {

	const { register, handleSubmit, formState: { errors } } = useForm();
	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();

	const onSubmit = (data: any) => {
		let params: any = {
			"email": data.email,
			"password": data.password
		}
		showLoader();
		userApi.login(params, (message: string, resp: any) => {
			// console.log({ resp })
			hideLoader();
		}, (message: string) => {
			toast.error(message)
			hideLoader();
		});
	}

	return (
		<React.Fragment>
			<section className="main-body">
				<div className="circle-bg"></div>
				<div className="two-division">
					<div className="left-part">
						<img src="./assets/img/login.png" alt="" />
					</div>
					<div className="right-part">
						<div className="login-box-wrap">
							<h1 className="logo-wrap"><img src="./assets/img/logo.png" alt="" /></h1>
							<div className="welcome-text">
								<h2>Let's Get <span>Started!</span></h2>
								<p>Enter your informations below or login with
									social account</p>
							</div>
							<div className="login-box">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="mb-3">
										<label htmlFor="" className="form-label">Username</label>
										<input type="text" className="form-control" placeholder="Enter Username" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
										{errors?.email ? <div className="invalid-feedback">
											{errors.email.type === "required" ? 'Required' : null}
											{errors.email.type === "pattern" ? 'Invalid email address' : null}
										</div> : null}
									</div>
									<div className="mb-3">
										<label htmlFor="" className="form-label">Password</label>
										<input type="password" className="form-control" placeholder="Enter Password" {...register("password", { required: true, maxLength: 80 })} />
									</div>
									<div className="mb-3">
										<Button className="btn btn-primary" type="submit">Login</Button>
										<Link to={URLS.FORGOT_PASSWORD} className="register-links ms-3">forgot password</Link>
									</div>
								</form>
							</div>
							<div className="or-wrap"><span>Or</span></div>
							<div className="or-wrap d-none"><span>Or Sign Up with</span></div>
							<div className="social-login-wrap d-none">
								<a href="#"><i className="fa-brands fa-facebook-f"></i></a>
								<a href="#"><i className="fa-brands fa-google"></i></a>
								<a href="#"><i className="fa-brands fa-twitter"></i></a>
							</div>
							<div className="register-links">New user? <Link to="/signup" >Register Now!</Link><p><Link to="/contact-us" >Contact us!</Link></p></div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
}