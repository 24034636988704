import '../styles/signup.scss';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import toast from 'react-hot-toast';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { URLS } from "../_config";
import LeftPanel from '../components/leftPanel';
import Header from '../components/header';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';

export default function ProfileUpdate() {

  const userApi = useUserApi();
  const navigate = useNavigate();
  const user: any = useUserSelector();

  const { showLoader, hideLoader } = useAppLoader();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [dobError, setDobError] = useState(false);

  const onSubmit = (data: any) => {
    console.log({ data })
    const dobDate = new Date(data.dob);
    if (dobDate > new Date()) {
      setDobError(true);
    } else {
      setDobError(false);
      let params: any = {
        "first_name": data.first_name,
        "last_name": data.last_name,
        'phone': data.phone,
        'email': data.email,
         'dob': data.dob,
        'username': user.username,
        'id': user.id
      }
      showLoader();
      userApi.profileUpdate(params, (message: string, resp: any) => {
        // console.log({ resp })
        hideLoader();
        toast.success(message);
        navigate(URLS.PROFILE)
      }, (message: string) => {
        toast.error(message)
        hideLoader();
      });
    }
  }

  useEffect(() => {
    if (user) {
      if (user.first_name) {
        setValue("first_name", user.first_name)
      }
      if (user.last_name) {
        setValue("last_name", user.last_name)
      }
      if (user.email) {
        setValue("email", user.email)
      }
      if (user.phone) {
        setValue("phone", user.phone)
      }
      if (user.dob) {
        setValue("dob", user.dob)
      }
      if (user.username) {
        setValue("username", user.username)
      }
      console.log({ user })
    }
  }, [user])

  return (
    <React.Fragment>
      <div className="circle-bg"></div>
      <Header />
      <LeftPanel />
      <section className="main-body">
        <div className="right-menu-part">
          <div className="dash-body">
            <div className="container-fluid">
              {/* <h1 className="logo-wrap"><img src="./assets/img/logo.png" alt="" /></h1> */}
              <div className="welcome-text">
                <h2><span>Profile</span></h2>
              </div>
              <div className="login-box">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className="mb-3 col-sm-6">
                      <label htmlFor="" className="form-label">First Name <span>*</span></label>
                      <input type="text" className="form-control" placeholder="Type First Name" {...register("first_name", { required: true })} />
                      {errors?.first_name ? <div className="invalid-feedback">
                        {errors.first_name.type === "required" ? 'Required' : null}
                      </div> : null}
                    </div>
                    <div className="mb-3 col-sm-6">
                      <label htmlFor="" className="form-label">Last Name <span>*</span></label>
                      <input type="text" className="form-control" placeholder="Type Last Name" {...register("last_name", { required: true })} />
                      {errors?.last_name ? <div className="invalid-feedback">
                        {errors.last_name.type === "required" ? 'Required' : null}
                      </div> : null}
                    </div>
                    <div className="mb-3 col-sm-6">
                      <label htmlFor="" className="form-label">Phone <span>*</span></label>
                      <input type="text" className="form-control" placeholder="Type Phone" {...register("phone", { required: true, maxLength: 10, minLength: 10 })} />
                      {errors?.phone ? <div className="invalid-feedback">
                        {errors.phone.type === "required" ? 'Required' : null}
                        {errors.phone.type === "maxLength" ? 'phone number must be of 10 digit' : null}
                        {errors.phone.type === "minLength" ? 'phone number must be of 10 digit' : null}
                      </div> : null}
                    </div>
                    <div className="mb-3 col-sm-6">
                      <label htmlFor="" className="form-label">Date of Birth <span>*</span></label>
                      {/* <Controller
                        control={control} name="dob"
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={value}
                            className="form-control"
                            maxDate={new Date()}
                            onChange={onChange}
                          />
                        )}
                      /> */}
                      <input type="date" className="form-control" placeholder="DOB" {...register("dob", { required: true })} max={new Date().toISOString().split("T")[0]} />
                      {errors?.dob ? <div className="invalid-feedback">
                        {errors.dob.type === "required" ? 'Required' : null}
                      </div> : null}
                      {dobError && <span className="invalid-feedback">Date of birth cannot be greater than today.</span>}
                    </div>
                    <div className="mb-3 col-sm-6">
                      <label htmlFor="" className="form-label">Email</label>
                      <input type="text" className="form-control" placeholder="Type Email" disabled {...register("email", { required: false, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                      {errors?.email ? <div className="invalid-feedback">
                        {errors.email.type === "required" ? 'Required' : null}
                        {errors.email.type === "pattern" ? 'Invalid email address' : null}
                      </div> : null}
                    </div>
                    <div className="mb-3 col-sm-6">
                      <label htmlFor="" className="form-label">Username</label>
                      <input type="text" className="form-control" placeholder="username" disabled {...register("username", { required: false })} />
                    </div>
                  </div>
                  <div>
                    <Button type='submit' className='btn btn-primary me-2'>Submit</Button>
                    <Link to={URLS.PROFILE} className='btn btn-outline-secondary'>Back</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}