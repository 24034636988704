import React from 'react'
import { useForm } from 'react-hook-form';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { URLS } from '../_config';
import { Button } from 'react-bootstrap';

const ForgotPassword = () => {

    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const { showLoader, hideLoader } = useAppLoader();
    const userApi = useUserApi();

    const onSubmit = (data: any) => {
        let params: any = {
            "email": data.email,
        }
        showLoader();
        userApi.forgotPassword(params, (message: string, resp: any) => {
            toast.success(message)
            hideLoader();
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    return (
        <React.Fragment>
            <section className="main-body">
                <div className="circle-bg"></div>
                <div className="two-division">
                    <div className="left-part">
                        <img src="./assets/img/login.png" alt="" />
                    </div>
                    <div className="right-part">
                        <div className="login-box-wrap">
                            <h1 className="logo-wrap"><img src="./assets/img/logo.png" alt="" /></h1>
                            <div className="welcome-text">
                                <h2>Let's Get <span>Started!</span></h2>
                                <p>Enter your username below</p>
                            </div>
                            <div className="login-box">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label">Username</label>
                                        <input type="text" className="form-control" placeholder="Enter Username" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                        {errors?.email ? <div className="invalid-feedback">
                                            {errors.email.type === "required" ? 'Required' : null}
                                            {errors.email.type === "pattern" ? 'Invalid email address' : null}
                                        </div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <Button className="btn btn-primary" type="submit">Submit</Button>
                                        <Link to="/login" className="btn btn-outline-secondary ms-3">Back to login</Link>
                                    </div>
                                </form>
                            </div>
                            <div className="register-links">New user? <Link to="/signup" >Register Now!</Link></div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ForgotPassword