import React from 'react'
import { useForm } from 'react-hook-form';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { URLS } from '../_config';
import { Button } from 'react-bootstrap';

const ResetPassword = () => {

    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const { showLoader, hideLoader } = useAppLoader();
    const userApi = useUserApi();
    const navigate = useNavigate();
    const id = useParams().id;

    const onSubmit = (data: any) => {
        console.log({ data })
        let params: any = {
            "token": id,
            "password": data.password,
            "c_password": data.c_password
        }
        showLoader();
        userApi.resetPassword(params, (message: string, resp: any) => {
            toast.success(message)
            navigate(URLS.LOGIN)
            hideLoader();
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    return (
        <React.Fragment>
            <section className="main-body">
                <div className="circle-bg"></div>
                <div className="two-division">
                    <div className="left-part">
                        <img src="./assets/img/login.png" alt="" />
                    </div>
                    <div className="right-part">
                        <div className="login-box-wrap">
                            <h1 className="logo-wrap"><img src="./assets/img/logo.png" alt="" /></h1>
                            <div className="welcome-text">
                                <h2>Let's Get <span>Started!</span></h2>
                                <p>Enter your new password below</p>
                            </div>
                            <div className="login-box">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label">Password <span>*</span></label>
                                        <input type="password" className="form-control" autoComplete="new-password" {...register("password", { required: true })} placeholder="Password" />
                                        {errors?.password ? <div className="invalid-feedback">
                                            {errors.password.type === "required" ? 'Required' : null}
                                        </div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label">Confirm Password <span>*</span></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            {...register("c_password", {
                                                required: true, validate: (value: any) => {
                                                    let { password } = getValues();
                                                    return password === value;
                                                }
                                            })}
                                            placeholder="Confirm Password"
                                        />
                                        {errors?.c_password ? <div className="invalid-feedback">
                                            {errors.c_password.type === "required" ? 'Required' : null}
                                            {errors.c_password.type === "validate" ? 'Passwords should match!' : null}
                                        </div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <Button className="btn btn-primary" type="submit">Submit</Button>
                                        <Link to="/login" className="btn btn-outline-secondary ms-3">Back to login</Link>
                                    </div>
                                </form>
                            </div>
                            <div className="register-links">New user? <Link to="/signup" >Register Now!</Link></div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ResetPassword